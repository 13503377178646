import React, { useEffect, useState } from 'react';
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';
import { AiOutlineZoomIn,AiOutlineZoomOut  } from "react-icons/ai";
import TextAsImage from './drawText'
import { object } from 'prop-types';
// calc(${fontSize}px + 1vw)

const  getSizeForWidth = (width, sizeChart)=> {
  if(typeof sizeChart != 'object') return `calc(${sizeChart}px + 1vw)`;

  // Convert the width to a number to ensure proper comparison
  width = parseInt(width);

  // Initialize a variable to store the size
  let selectedSize = null;

  // Iterate through the keys of the size chart object
  Object.keys(sizeChart).forEach(key => {
      // Convert the key (width) to a number
      const chartWidth = parseInt(key);

      // Check if the given width is less than or equal to the current chart width
      if (width <= chartWidth) {
          // If the size hasn't been assigned yet or the current chart width is smaller, update the selected size
          if (!selectedSize || chartWidth < parseInt(Object.keys(sizeChart)[0])) {
              selectedSize = sizeChart[key];
          }
      }
  });
  console.log(selectedSize,'size');
  // Return the selected size
  return selectedSize;
}
const isIphone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
const { innerWidth: width, innerHeight: height } = window;

const DraggableText = ({ text, cordinates,disabled=false,removeTimeResolution,disableDrag=false,color ='#fff', size = 12, isBetween=false, renderResolution={}}) => {

  const [position, setPosition] = useState(cordinates);
  const [fontSize, setFontSize] = useState(getSizeForWidth(width, size));

  useEffect(() => {
    setPosition(cordinates);
  }, [cordinates]);

  const paddingTopEventSpecific = ()=>{
    if(isIphone){
      return 0;
    } else if(width >= 600){
      return 0;
    }else{
      return 0.5;
    }

  };
  const handleDragStart = (e) => {
    if(disabled || disableDrag) return;
    e.preventDefault();
    const clientX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
    const clientY = e.type === 'touchstart' ? e.touches[0].clientY : e.clientY;

    const offsetX = clientX - position.x;
    const offsetY = clientY - position.y;

    const handleDragMove = (e) => {
      const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
      const clientY = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;

      const newX = clientX - offsetX;
      const newY = clientY - offsetY;
      setPosition({ x: newX, y: newY });
    };

    const handleDragEnd = () => {
    if(disabled || disableDrag) return;

      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchend', handleDragEnd);
    };
    if(disabled || disableDrag) return;

    document.addEventListener('mousemove', handleDragMove);
    document.addEventListener('touchmove', handleDragMove);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchend', handleDragEnd);
  };

  const handleFontSizeChange = (increase) => {
  let font = eval(fontSize.replace('px',''));
  let newFontSize =  (increase ? font + 2 : font - 2);
    setFontSize(`${newFontSize}px`);
  };

  
  return (
    <>
    {
      isBetween && 
      <div style={isIphone ? {display:'flex',alignItems:'flex-end', paddingTop:1.5} : {display:'flex',alignItems:'flex-end'}}>
      {/* <TextAsImage text={text} fontSize={5.5} height={renderResolution.height} width={renderResolution.width} /> */}
            {/* <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>*/}
            <span className='Conamore-Bold'  style={{lineHeight:1,margin:0,fontSize: fontSize, paddingLeft:2, paddingRight:2, fontFamily:'Conamore-Bold' }}>
      {text}
    </span> 

      </div>
    ||
   
    <div
      style={{
        position: 'absolute',
        left: position.x,
        top: position.y,
        cursor: 'move',
        zIndex: 999,
        display: 'flex',
        color: '#fff',
        fontWeight: 600,
        background:disabled ? 'red' : 'transparent', 
      }}
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
    >
      {!disabled &&
      <span className='Conamore-Bold' style={{ fontSize: fontSize, cursor:disableDrag ? 'default' : 'move',whiteSpace:'nowrap',color:color }}>
        {text}
      </span> ||
        <span style={{width:removeTimeResolution.width,height:removeTimeResolution.height,backgroundColor:'#134d92'}}/>
      }
      {(!disabled && !disableDrag) &&
      <div>
        <div className="no-print" style={{ paddingLeft: 10, cursor: 'pointer',display:'flex',flexDirection:"row" }}>
          <AiOutlineZoomIn   style={{fontSize:20,color:'#FFD800'}} tooltop onClick={() => handleFontSizeChange(true)} />
          <AiOutlineZoomOut  style={{fontSize:20,color:'#FFD800'}}    onClick={() => handleFontSizeChange(false)} />
        </div>
        </div>

      }
    </div>
     }
    </>
  );
};

export default DraggableText;
